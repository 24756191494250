import React from 'react';
import './styles.css';

function PrivacyPolicy({ quizData }) {
  const { title, lastUpdated, sections } = quizData.quiz.privacyPolicy;

  return (
    <div className="privacy-policy-container">
      <h1>{title}</h1>
      <p className="last-updated">{lastUpdated}</p>
      
      {sections.map((section, index) => (
        <section key={index} className="privacy-section">
          <h2>{section.title}</h2>
          <p>{section.content}</p>
          {section.items && (
            <ul>
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
}

export default PrivacyPolicy;