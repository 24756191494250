import React, { useState }  from 'react';
import { useLocation } from 'react-router-dom';
// Import Chart and elements from chart.js
import { Chart, registerables, ArcElement, RadialLinearScale, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import './styles.css';
import { getSessionEventId } from './utils/metaPixelUtils';
import quizData from './quizData';
import CryptoJS from 'crypto-js'; // Ensure you have this import for decryption



// This is the results page that displays the results of the quiz
// Overall quiz sequence is:
// 1. User answers each questoin.
// 2. A results page pops up but is obscured by a "wall of email"
// 3. User enters email and clicks submit
// 3.1 A simple encrptyion key and session id are generated.
// 3.2 The scores and email are encrypted with the key and stored in a cookie.
// 3.3 A results retrival url is generated with the key and session id as part of the url in the format:
//     https://wellness-wheel.com/results?data=<key>&session=<session>
// 4. The email entry box is replaced with a "thank you" message and the email is sent to the user
// 5. A zapier webhook is triggered to send an email to the user with the verification url
// 6. The user clicks the link in the email and is redirected to the results page.
// 7. The scores and email are decrypted with the key and scores are displayed on the page.
// 8. A zapier webhook is triggered to enroll the user in the nurture sequence.

// Create a mapping of categories to question numbers
const categoryToQuestionNumberMap = {};
quizData.quiz.questions.forEach(question => {
    categoryToQuestionNumberMap[question.category] = question.questionNumber;
  });


// Function to get question number by category
function getQuestionNumberByCategory(category) {
  return categoryToQuestionNumberMap[category] || null; // Return null if category not found
}

// Function to decrypt data
function decryptData(encryptedData, secretKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// Function to generate a random secret key
async function generateSecretKey() {
  const key = CryptoJS.lib.WordArray.random(16); // Generate a random 128-bit key
  return key.toString(); // Convert to string for storage
}

// Function to set the secret key in a cookie
function setSecretKeyCookie(key) {
  const cookieName = "decryptionKey";
  const cookieValue = key; // This should be a strong key
  const expirationDays = 1; // Set expiration time
  const date = new Date();
  date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; Secure; HttpOnly`;
}


// Register all controllers, elements, scales, and plugins from the registerables array
Chart.register(...registerables);

function Results({ scores, quizData }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlData = queryParams.get('data');



  console.log(location);

  const [currentStep, setCurrentStep] = useState('email'); // 'email', 'role', 'leadership', 'results'
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [isLeadershipSupport, setIsLeadershipSupport] = useState(null);
  const [isPreQualified, setIsPreQualified] = useState(null);
  const categories = Object.keys(scores);
  const maxRange = quizData.quiz.questions[0].range.max;

  const bgColorList = [
    'rgba(208, 96, 71, 0.6)', // Tomato
    'rgba(201, 149, 40, 0.6)', // Ochre
    'rgba(164, 163, 122, 0.6)', // Sage
    'rgba(118, 108, 138, 0.6)', // Amethyst
    'rgba(217, 162, 127, 0.6)', // Clay
    'rgba(175, 165, 190, 0.6)', // Lavender
    'rgba(72, 134, 147, 0.6)', // Teal
    'rgba(242, 175, 133, 0.6)', // Peach
    'rgba(128, 130, 74, 0.6)', // Olive
    'rgba(188, 140, 148, 0.6)', // Dusty Rose
  ];
  const borderColorList = [
    'rgba(208, 96, 71, 1)', // Tomato
    'rgba(201, 149, 40, 1)', // Ochre
    'rgba(164, 163, 122, 1)', // Sage
    'rgba(118, 108, 138, 1)', // Amethyst
    'rgba(217, 162, 127, 1)', // Clay
    'rgba(175, 165, 190, 1)', // Lavender
    'rgba(72, 134, 147, 1)', // Teal
    'rgba(242, 175, 133, 1)', // Peach
    'rgba(128, 130, 74, 1)', // Olive
    'rgba(188, 140, 148, 1)', // Dusty Rose
  ];
  
  const roleOptions = [
    { label: 'Business Owner / Entrepreneur', preQualified: true },
    { label: 'CEO / Executive Director / Founder', preQualified: true },
    { label: 'VP / Director-Level Leader', preQualified: true },
    { label: 'Manager / Team Lead', preQualified: true },
    { label: 'Individual Contributor', preQualified: true },
    { label: 'Direct Report', preQualified: false },
    { label: 'Freelancer', preQualified: false },
    { label: 'Assistant', preQualified: false },
    { label: 'Student', preQualified: false }
  ];

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setEmailSubmitted(true);
    setCurrentStep('results');
    
    // Track email submission
    window.gtag('config', quizData.config.googleTag.id);
    window.gtag('event', 'conversion', {'send_to': quizData.config.googleTag.conversionId});
    
    try {
      if (window.fbq) {
        window.fbq('track', 'Lead', {
          contents: [{ id: 'burnout_assessment', quantity: 1 }],
          content_type: 'product',
          eventID: quizData.config.metaPixel.eventId
        });
      }
    } catch (error) {
      console.error('Error tracking Meta event:', error);
    }

    // Calculate average scores for segmentation
    const averageScores = {};
    Object.keys(scores).forEach(category => {
      const total = scores[category].reduce((acc, curr) => acc + curr, 0);
      averageScores[category] = total / scores[category].length;
    });

    try {
      // Skip Zapier webhook for test email
      if (email.toLowerCase() !== quizData.config.testEmail) {
        // Always send to pre-qualified Zapier webhook
        const zapierResponse = await fetch(quizData.config.zapier.preQualified, {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            role: 'Not Asked',
            seekingLeadershipSupport: 'Not Asked',
            isPreQualified: true,
            scores: averageScores,
            timestamp: new Date().toISOString()
          }),
        });

        if (!zapierResponse.ok) {
          throw new Error('Network response was not ok');
        }
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleRoleSelect = (selectedRole) => {
    setRole(selectedRole);
    setCurrentStep('leadership');
  };

  const handleLeadershipSelect = async (answer) => {
    setIsLeadershipSupport(answer);
    
    // Determine if pre-qualified based on role and leadership support answer
    const selectedRoleObj = roleOptions.find(option => option.label === role);
    const qualified = selectedRoleObj.preQualified && answer === 'Yes';
    setIsPreQualified(qualified);
    setCurrentStep('results');

    // Calculate average scores for segmentation
    const averageScores = {};
    Object.keys(scores).forEach(category => {
      const total = scores[category].reduce((acc, curr) => acc + curr, 0);
      averageScores[category] = total / scores[category].length;
    });

    try {
      // Skip Zapier webhook for test email
      if (email.toLowerCase() !== quizData.config.testEmail) {
        // Send to Zapier webhook with qualification data
        const zapierUrl = qualified ? quizData.config.zapier.preQualified : quizData.config.zapier.nonPreQualified;
        const zapierResponse = await fetch(zapierUrl, {
          method: 'POST',
          body: JSON.stringify({
            email: email,
            role: role,
            seekingLeadershipSupport: answer === 'Yes',
            isPreQualified: qualified,
            scores: averageScores,
            timestamp: new Date().toISOString()
          }),
        });

        if (!zapierResponse.ok) {
          throw new Error('Network response was not ok');
        }
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  // Prepare data for the PolarArea chart
  const data = {
    labels: Object.keys(scores),
    datasets: [{
      label: 'Capacity Score',
      data: Object.keys(scores).map(category => {
        const categoryScores = scores[category];
        const averageScore = categoryScores.reduce((a, b) => a + b, 0) / categoryScores.length;
        return averageScore;
      }),
      backgroundColor: bgColorList,
      borderColor: borderColorList,
      borderWidth: 1,
    }]
  };

    // Data for the "Full Well" and "Empty Well" PolarArea charts
    const fullWellData = {
      labels: categories,
      datasets: [{
        data: categories.map(() => maxRange),
        backgroundColor: bgColorList,
        borderColor: borderColorList,
        borderWidth: 1,
      }]
    };
  
    const emptyWellData = {
      labels: categories,
      datasets: [{
        data: categories.map(() => 1),
        backgroundColor: bgColorList,
        borderColor: borderColorList,
        borderWidth: 1,
      }]
    };
  const options = {
    
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: quizData.quiz.questions[0].range.max
      }
    },
    plugins: {
      legend: {
        position: 'bottom', // Adjust as needed
        labels: {
          usePointStyle: false,
        },
      },
    }
  };

  const optionsSmall = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: quizData.quiz.questions[0].range.max
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    }
  };

// Calculate average scores for each category
const averageScores = {};
Object.keys(scores).forEach(category => {
  const total = scores[category].reduce((acc, curr) => acc + curr, 0);
  const average = total / scores[category].length;
  averageScores[category] = average;
});

// Convert averageScores to an array and sort by score
const sortedScores = Object.keys(averageScores).map(category => ({
  category,
  score: averageScores[category]
})).sort((a, b) => a.score - b.score);

  // Determine the lowest two categories, including ties for the second lowest score
  let lowestCategories = [sortedScores[0]];
  let secondLowestScore = sortedScores[1].score; // Start with the second item as the next lowest score

  for (let i = 1; i < sortedScores.length; i++) {
    if (sortedScores[i].score <= secondLowestScore) {
      lowestCategories.push(sortedScores[i]);
      secondLowestScore = sortedScores[i].score;
    } else break; // Stop if the next score is higher than the second lowest
  }





  return (
    <div className="results-container">
      {currentStep === 'email' && (
        <div className="email-wall">
          <form onSubmit={handleEmailSubmit} className="email-form">
            <div className="email-input-container">
              <label htmlFor="email">{quizData.quiz.emailPrompt}</label>
              <input 
                type="email" 
                id="email"  
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoFocus="autofocus"
              />
              <button type="submit">Submit</button>
            </div>
            <p className="privacy-notice">
              {quizData.quiz.privacyNotice} <a href={quizData.quiz.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a> for more details.
            </p>
          </form>
        </div>
      )}

      <div className={`results-content ${currentStep === 'results' ? '' : 'blur'}`}>
        <h2 className="results-title">{quizData.quiz.results.title}</h2>
        <p className="big-chart-title">{quizData.quiz.results.chartTitle}</p>
        <div className="main-chart-container">
          <PolarArea data={data} options={options} />
        </div>
        <div className="small-charts-container">
          <div className="small-chart">
            <p className="chart-title">{quizData.quiz.results.charts.fullWell}</p>
            <PolarArea data={fullWellData} options={optionsSmall}/>
          </div>
          <div className="small-chart">
            <p className="chart-title">{quizData.quiz.results.charts.emptyWell}</p>
            <PolarArea data={emptyWellData} options={optionsSmall}/>
          </div>
        </div>

        <div className="response-message">
          <p className="assessment-thank-you">{quizData.quiz.results.responseMessage.thankYou}</p>
          <p>{quizData.quiz.results.responseMessage.intro}</p>
          <p>{quizData.quiz.results.responseMessage.energyDebt}</p>
          <p>{quizData.quiz.results.responseMessage.burnoutExplanation}</p>
          
          <p className="checklist-item">{quizData.quiz.results.responseMessage.checklist.intro}</p>
          {quizData.quiz.results.responseMessage.checklist.items.map((item, index) => (
            <p key={index} className="checklist-item">✅ {item}</p>
          ))}
          <p>{quizData.quiz.results.responseMessage.checklist.outro}</p>
          
          <p>{quizData.quiz.results.responseMessage.depleters.intro} {
            lowestCategories.length > 1 
              ? `${lowestCategories.slice(0, -1).map(c => c.category).join(', ')}, and ${lowestCategories.slice(-1)[0].category}`
              : lowestCategories[0].category
          }.</p>
          {lowestCategories.map((category, index) => (
            <div key={index}>
              <div className="response-message-category">
                <p><strong>{quizData.categoryResponses.categoryResponse[category.category].title}</strong></p>
              </div>
              <p>{quizData.categoryResponses.categoryResponse[category.category].explanation}</p>
              <ol>
                {quizData.categoryResponses.categoryResponse[category.category].suggestions.map((suggestion, index) => (
                  <li key={index}>{suggestion}</li>
                ))}
              </ol>
            </div>
          ))}
          <p>{quizData.categoryResponses.lastResponse} 
            <a href={quizData.categoryResponses.lastResponseLink} target="_blank" rel="noopener noreferrer">Shift the Way You Lead.</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Results;
