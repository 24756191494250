const quizData = {
    config: {
        zapier: {
            preQualified: 'https://hooks.zapier.com/hooks/catch/20636901/2lf5ac2/',
            nonPreQualified: 'https://hooks.zapier.com/hooks/catch/20636901/2k8fyqs/'
        },
        metaPixel: {
            eventId: 'getSessionEventId'
        },
        googleTag: {
            id: 'AW-684082633',
            conversionId: 'AW-684082633/UNQ0CK7i48cZEMmLmcYC'
        },
        testEmail: 'test@test123.com'
    },
    quiz: {
        "title": "Welcome to Noria's Burnout Assessment",
        'subtitle': "Discover What Depletes Your Innerwell",
        "logo_url": "https://images.squarespace-cdn.com/content/v1/62991701124f0a3e2cd69968/bc54da16-0ff1-4575-ad46-92e22eda6deb/Circle.gif",
        "response_url": "https://www.dataxtractr.com/noria_quiz_email",
        "emailPrompt": "Enter your email to see your results:",
        "privacyNotice": "By submitting your email, you agree to receive marketing communications from us. We respect your privacy: your quiz responses will not be stored, and we will never sell your information. You can unsubscribe at any time. See our Privacy Policy for more details.",
        "privacyPolicyUrl": "/#/privacy-policy",
        "results": {
            "title": "Noria Burnout Assessment Results",
            "chartTitle": "Hover over each segment to see its category.",
            "charts": {
                "fullWell": "Full Well",
                "emptyWell": "Empty Well"
            },
            "responseMessage": {
                "thankYou": "Thank you for taking the Noria Burnout Assessment!",
                "intro": "Your results reveal your burnout profile—a measure of the key factors that fuel or drain your capacity to lead effectively. Some areas may be strong and well-resourced, while others may be running low.",
                "energyDebt": "⚡ If several areas are depleted, you may be operating in energy debt—at risk of burnout and decreased leadership effectiveness.",
                "burnoutExplanation": "Burnout isn't just about working too much. It's a result of misaligned energy, systemic challenges, and unbalanced leadership demands. The good news? With the right strategy, you can recalibrate your energy, increase your resilience, and lead at your highest level.",
                "checklist": {
                    "intro": "Your personalized breakdown below will help you:",
                    "items": [
                        "Identify your biggest energy drains",
                        "Understand how they impact your leadership effectiveness",
                        "Take strategic action to realign, replenish, and sustain your energy"
                    ],
                    "outro": "Let's dive into your results and create a plan to keep you ahead of burnout—so you can lead with clarity, confidence, and impact."
                },
                "depleters": {
                    "intro": "Your most significant depleters are"
                }
            }
        },
        "privacyPolicy": {
            "title": "Privacy Policy",
            "lastUpdated": "Last Updated: March 2024",
            "sections": [
                {
                    "title": "Introduction",
                    "content": "At Noria, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information when you use our Burnout Assessment tool."
                },
                {
                    "title": "Information We Collect",
                    "content": "When you take our Burnout Assessment, we collect:",
                    "items": [
                        "Your email address (if you choose to provide it)",
                        "Your responses to the assessment questions",
                        "Technical information such as your IP address and browser type"
                    ]
                },
                {
                    "title": "How We Use Your Information",
                    "content": "We use your information to:",
                    "items": [
                        "Provide you with your assessment results",
                        "Send you your results via email (if requested)",
                        "Improve our assessment tool and services",
                        "Communicate with you about our services (if you've opted in)"
                    ]
                },
                {
                    "title": "Data Protection",
                    "content": "We implement appropriate security measures to protect your personal information. Your quiz responses are encrypted and not stored permanently on our servers."
                },
                {
                    "title": "Your Rights",
                    "content": "You have the right to:",
                    "items": [
                        "Access your personal information",
                        "Request deletion of your information",
                        "Opt-out of marketing communications",
                        "Request a copy of your data"
                    ]
                },
                {
                    "title": "Contact Us",
                    "content": "If you have any questions about this Privacy Policy, please contact us at info@hellonoria.com"
                }
            ]
        },
        "questions": [
            {
            "questionNumber": 1,
            "questionType": "Rating Scale",
            "title": "Lack of Boundaries",
            "category": "Lack of Boundaries",
            "prompt": "On a scale of 1-10, please rate your relationship with work boundaries:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I consistently work beyond my normal work hours & do not have clear work/life boundaries.",
                "maxValueAnswer": "I have very clear boundaries with work and prioritize my own self-care and needs over work obligations."
            }
            },
            {
            "questionNumber": 2,
            "questionType": "Rating Scale",
            "title": "Perfectionism",
            "category": "Perfectionism",
            "prompt": "On a scale of  1-10, please rate your tendency towards perfection",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I'm uncomfortable with submitting work that is anything less than perfect, or close to it and will work beyond normal work hours to achieve the quality of work I desire.",
                "maxValueAnswer": "I am able to do a 'good enough job' in order to maintain healthy work boundaries and time for myself."
            }
            },
            {
            "questionNumber": 3,
            "questionType": "Rating Scale",
            "title": "Low Resiliency (rolling with difficult times)",
            "category": "Lack of Resiliency",
            "prompt": "On a scale of 1-10, please rate your ability to cope with stressors at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "Work difficulties often affect me in a big, negative way (e.g. when I am faced with a challenge at work, I take it very personally and have a hard time \"leaving it at work\" or  I avoid the problem or give up).",
                "maxValueAnswer": "I roll with big challenges and view them as inevitable and part of my growth."
            }
            },
            {
            "questionNumber": 4,
            "questionType": "Rating Scale",
            "title": "Unmanageable Workload",
            "category": "Overwhelming Workload",
            "prompt": "On a scale of 1-10, please rate the level of your workload:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "My workload feels insurmountable.",
                "maxValueAnswer": "My workload is manageable, I can get my work done during work hours."
            }
            },
            {
            "questionNumber": 5,
            "questionType": "Rating Scale",
            "title": "Lack of Autonomy",
            "category": "Lack of Autonomy",
            "prompt":  "On a scale of 1-10, please rate your experience with workplace autonomy:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I feel controlled and micromanaged when I am at work.",
                "maxValueAnswer": "I have control over my day-to-day existence at work (e.g. I take breaks when I need them, my schedule is adaptable, I have the freedom to work the way I want)."
            }
            },
            {
            "questionNumber": 6,
            "questionType": "Rating Scale",
            "title": "Lack of Recognition",
            "category": "Lack of Recognition",
            "prompt": "On a scale of 1-10, please rate your experience with recognition at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I feel like I am not valued or recognized for the good work I do; others get rewarded over me. I feel invisible.",
                "maxValueAnswer": " I am valued, rewarded, and fully recognized for the work that I do. I feel seen and appreciated."
            }
            },
            {
            "questionNumber": 7,
            "questionType": "Rating Scale",
            "title": "Poor Relationships",
            "category": "Sub-par Relationships",
            "prompt": "On a scale of 1-10, please rate the quality of your relationships at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I do not have a strong connection or trust/rapport with my team members or leaders.",
                "maxValueAnswer": "I have stellar relationships with the people I work with and feel a sense of belonging and trust."
            }
            },
            {
            "questionNumber": 8,
            "questionType": "Rating Scale",
            "title": "Misalignment of Values",
            "category": "Misalignment of Values",
            "prompt": "On a scale of 1-10, please rate how well your values are aligned with the work that you do:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I do not derive any meaning or purpose from the work that I do; it is simply a job I have to do.",
                "maxValueAnswer": "While at work, I am aligned with my values and connected to a sense of meaning and purpose. I am contributing to something bigger than myself."
            }
            }
        ]

    },
    categoryResponses: {
        "categoryResponse": {
            "Lack of Boundaries": {
              "title": "Energy Drain: Lack of Boundaries - Protecting Your Leadership Capacity",
              "explanation": "Your leadership energy is not an unlimited resource—and without strong boundaries, work will consume more than its fair share. Being constantly available, overcommitting, or struggling to unplug doesn't just exhaust you—it diminishes your ability to lead effectively. Great leaders protect their time, focus, and well-being so they can show up with clarity, confidence, and impact. Here's how you can start reclaiming yours:",
              "suggestions": [
                "Redefine boundaries as leadership strength: Saying \"no\" isn't a weakness—it's an act of strategic energy management. Start small: Decline one unnecessary request and reflect on the outcome. Did your worst fear come true, or did you just create space for what truly matters?",
                "Create a transition ritual: Signal the end of your workday with a clear boundary-setting activity. Whether it's writing down unfinished tasks for tomorrow, taking a walk, or playing with your kids, this ritual trains your brain to shift out of work mode.",
                "Limit technology's access to you: Constant notifications keep your nervous system on high alert. Turn off work messages after hours, remove email from your phone, and keep devices out of your bedroom. This isn't avoidance—it's protecting your leadership energy for what truly needs it."
              ]
            },
            "Perfectionism": {
              "title": "Energy Drain: Perfectionism - Releasing the Need for Overperformance",
              "explanation": "Your high standards have helped you excel—but when perfectionism takes over, it drains your energy and limits your impact. The constant drive to get things \"just right\" can lead to overwork, self-criticism, and an inability to delegate or step back. Here's the truth: You produce your best work when you are well-rested, clear-headed, and focused on progress—not perfection.",
              "suggestions": [
                "Recognize when perfectionism is running the show. Notice your thoughts and behaviors without judgment. Are you over-editing an email? Redoing work that was already good enough? Struggling to move forward because something isn't \"perfect\" yet?",
                "Reframe failure as growth. Perfectionism makes you fear mistakes, but mistakes are how leaders learn and evolve. What if you saw them as data points, not personal flaws?",
                "Experiment with \"good enough.\" Challenge yourself to stop tweaking at 90% instead of 100%. Delegate a task without hovering. See what happens when you let go—even slightly. You might be surprised by how little it affects the outcome."
              ]
            },
            "Lack of Resiliency": {
              "title": "Energy Drain: Low Resilience",
              "explanation": "Resilience is the foundation of sustainable leadership—the ability to navigate uncertainty, adapt to challenges, and maintain your energy over time. If you're feeling easily overwhelmed by changes or setbacks, it's a sign your Leadership Energy Reserve is running low. The key isn't just pushing through—it's building capacity to handle stress without depleting yourself. Here's how:",
              "suggestions": [
                "Recognize the signs: When frustration, exhaustion, or doubt start to take over, pause. These signals aren't failures—they're feedback. Acknowledge them without letting them dictate your next move.",
                "Regulate before you react: Leaders who manage their energy well know how to reset before they respond. Grounding practices—breathwork, movement, brief mental resets—can help you shift from reactivity to resilience.",
                "Reframe the challenge: Instead of asking, Why is this happening to me? shift to How can I grow from this? Extract the lesson, connect it to your bigger mission, and move forward with clarity."
              ]
            },
            "Overwhelming Workload": {
              "title": "Energy Drain: Overwhelming Workload - Reclaiming Your Leadership Focus",
              "explanation": "Your plate is full—and then some. When your workload becomes unmanageable, it's not just exhausting—it limits your ability to lead effectively. Constantly running on overdrive depletes your clarity, decision-making, and ability to focus on what truly matters. Great leaders don't just manage time—they manage priorities and protect their capacity. Here's how you can start reclaiming yours:",
              "suggestions": [
                "Assess what led you here. Overwork isn't always about the work itself—it's often about unspoken expectations, difficulty saying no, or a lack of support. What patterns have contributed to your workload? What needs to change?",
                "Have the conversation. Talk to your manager (or yourself, if you're the boss) about workload realities. Frame it around impact: \"My bandwidth is maxed out, and I want to ensure I'm delivering at my highest level. Can we prioritize or redistribute?\"",
                "Delegate strategically. Leadership isn't about doing everything—it's about empowering others to take ownership. What tasks can be handed off? Investing time in delegation now will save you hours of stress later.",
                "Set boundaries and practice saying \"no.\" Not everything requires your time. Try: 'I don't have the bandwidth to take this on, but I can suggest someone who might.'"
              ]
            },
            "Lack of Autonomy": {
              "title": "Energy Drain: Lack of Autonomy - Reclaiming Ownership Over Your Work",
              "explanation": "Feeling like you have little control over your schedule, decisions, or workload is one of the fastest ways to drain your leadership energy. When autonomy is limited—whether due to a rigid work culture, a packed schedule, or micromanagement—it can lead to frustration, disengagement, and burnout. But here's the truth: Even within constraints, there are ways to create more flexibility, independence, and ownership over your work.",
              "suggestions": [
                "Identify what's restricting you. Is it external (company culture, leadership style) or internal (hesitancy to push back, taking on too much)? Understanding what's causing the lack of autonomy helps you take targeted action.",
                "Define what you need. More decision-making power? A more flexible schedule? Less oversight? Clarity on your ideal work structure allows you to advocate for meaningful change.",
                "Communicate your needs. Whether it's a conversation with your manager, a renegotiation of responsibilities, or a shift in your own mindset, naming what you need is the first step toward getting it. Try: 'I do my best work with more ownership over my projects—how can we structure things to allow for more autonomy?'",
                "Create pockets of independence. Even in restrictive environments, you can find small ways to take ownership. Whether it's setting clear boundaries on your availability, structuring your day differently, or proactively proposing new ideas, these shifts can help you regain a sense of control."
              ]
            },
            "Lack of Recognition": {
              "title": "Energy Drain: Lack of Recognition - Reclaiming Your Leadership Value",
              "explanation": "Feeling invisible or undervalued can be one of the most depleting drains on your leadership energy. When your contributions aren't acknowledged, it can lead to frustration, disengagement, and a sense that your effort isn't making an impact. But here's the truth: Your value doesn't decrease just because others fail to see it. Strong leaders know how to advocate for themselves and shape the recognition they deserve.",
              "suggestions": [
                "Assess the root cause: Is this a company-wide culture issue? A leadership gap? A manager who lacks the skill to provide meaningful recognition? Identifying the source helps you navigate your next steps strategically.",
                "Own your impact: Instead of waiting for recognition, lead the conversation. Track your wins, articulate your contributions, and connect your work to broader business outcomes. Clarity creates visibility.",
                "Have the courageous conversation: Recognition isn't just about praise—it's about alignment. Be direct: \"I want to ensure my contributions are seen and valued. How can we create more transparency around impact and success?\""
              ]
            },
            "Sub-par Relationships": {
              "title": "Energy Drain: Sub-par Relationships - Strengthening Your Leadership Network",
              "explanation": "Leadership isn't just about what you know—it's about who you're connected to. A lack of strong, supportive relationships at work can leave you feeling isolated, unheard, or even disconnected from your purpose. Your leadership energy thrives in an environment of trust, collaboration, and shared vision. If your work relationships feel transactional or strained, it's time to proactively build the connections you need to lead at your highest level.",
              "suggestions": [
                "Identify the gaps: Is remote work creating distance? Do you struggle to find common ground with colleagues? Are you in a culture that lacks psychological safety? Pinpointing the disconnect helps you take action.",
                "Initiate meaningful connection: Strong relationships don't just happen—they're built. Seek out collaborative opportunities, informal check-ins, or shared interests that bring a sense of ease and camaraderie into your work environment.",
                "Expand your leadership community: If your workplace lacks connection, invest in relationships outside of it. Join leadership groups, mentorship circles, or purpose-driven communities that align with your values."
              ]
            },
            "Misalignment of Values": {
              "title": "Energy Drain: Misalignment of Values - Leading with Purpose & Integrity",
              "explanation": "When your work and leadership don't align with your core values, it drains your energy faster than any long hours or tough challenges ever could. Even if your job doesn't need to be your life's purpose, there must be enough alignment to sustain your motivation, integrity, and fulfillment.",
              "suggestions": [
                "Clarify your core values: What truly matters to you in your work? Growth? Impact? Innovation? Autonomy? List your top five values—these are your leadership compass.",
                "Assess the alignment: Does your current role support these values? If not, where are the gaps? Is it the company culture, leadership approach, or the work itself? Misalignment often shows up as frustration, disengagement, or a sense of \"going through the motions.\"",
                "Create realignment: Small shifts can make a big difference. Can you reframe your role to connect more with meaningful work? Advocate for changes that bring your work closer to your values? If deep misalignment remains, it may be time to explore a path that fuels your leadership energy rather than drains it."
              ]
            }
        },
        "lastResponse": "To learn more, check out our course ",
        "lastResponseLink": "https://hellonoria.com/women"      
    }
  };
  
  export default quizData;